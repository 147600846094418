import React, { createContext, useContext, useCallback, useMemo } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck } from "react-icons/fa";

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const defaultOptions = useMemo(
    () => ({
      position: "top-right",
      icon: (
        <span
          style={{
            backgroundColor: "#96298d",
            color: "white",
            borderRadius: "50%",
            padding: "2px 4px",
            marginRight: "10px",
          }}
        >
          <FaCheck />
        </span>
      ),
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    }),
    []
  );

  const showToast = useCallback(
    (type, message, options = {}) => {
      const toastOptions = { ...defaultOptions, ...options, progressStyle: {} };

      switch (type) {
        case "success":
          toastOptions.progressStyle = { backgroundColor: "#96298d" };
          toast.success(message, toastOptions);
          break;
        case "error":
          toastOptions.progressStyle = { backgroundColor: "red" };
          toast.error(message, toastOptions);
          break;
        case "warning":
          toastOptions.progressStyle = { backgroundColor: "orange" };
          toast.warning(message, toastOptions);
          break;
        case "info":
          toastOptions.progressStyle = { backgroundColor: "blue" };
          toast.info(message, toastOptions);
          break;
        default:
          toastOptions.progressStyle = { backgroundColor: "gray" };
          toast(message, toastOptions);
      }
    },
    [defaultOptions]
  );

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
