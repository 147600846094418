import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../../components/Authentication/AuthLayout";
import LoginForm from "../../components/Authentication/Login/LoginForm";
import SignupForm from "../../components/Authentication/SignUp/SignupForm";
import NotFound from "../404";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export const Login = () => {
  return <LoginForm />;
};

export const SignUp = () => {
  return <SignupForm />;
};

export default AuthRoutes;
