import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardHeader from "../Dashboard/Header/DashboardHeader";
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import { HeaderProvider } from "../../../context/LayoutContext";
import "./layout.css";

const MainLayout = () => {
  const token = sessionStorage.getItem("token");

  if (token) {
    return (
      <HeaderProvider>
        <div className="d-flex flex-row min-vh-100">
          {/* Sidebar */}
          <div className="sidebar-container flex-shrink-0">
            <Sidebar />
          </div>

          {/* Main content area */}
          <div className="main-content flex-grow-1 d-flex flex-column">
            {/* Dashboard Header */}
            <div className="dashboardheader-container">
              <DashboardHeader />
            </div>

            {/* Main Content (Outlet) */}
            <div
              className="content-container flex-grow-1 p-3 overflow-auto"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </HeaderProvider>
    );
  }
  return <Navigate to="/login" />;
};

export default MainLayout;
