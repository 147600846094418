import { Shield, BadgeCheck } from "lucide-react";
import { useAuth } from "../../../../context/AuthContext";
import "../../Verification/verifyaccount.css";

export const StatusButton = ({ className = "" }) => {
  const { accountStatus } = useAuth();

  const isVerified = accountStatus === "Approved";

  return (
    <button
      className={`btn btn-verified w-100 d-flex align-items-center justify-content-center fw-semibold ${className}`}
      disabled={isVerified}
    >
      {isVerified ? (
        <>
          <BadgeCheck size={20} className="me-2" /> ID Verified
        </>
      ) : (
        <>
          <Shield size={20} className="me-2" /> Verify ID
        </>
      )}
    </button>
  );
};
