import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Header/header.css";
import Responsive from "./HeaderMobile";
import ProfileDropdown from "../../DriversPortal/Dashboard/Header/ProfileDropdown";
import { useAuth } from "../../../context/AuthContext";
import { RiHome3Fill } from "react-icons/ri";

import { faTaxi, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";

const Navbartwo = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { user } = useAuth();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY >= 250) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="theme-1" id="header">
      <div className={`header__upper ${isSticky ? "sticky" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="header__upper--left">
                <div className="d-none d-lg-block logo">
                  <a href="/">
                    <img
                      style={{ maxWidth: "54%" }}
                      src="/enc/images/logo_3.png"
                      alt="TripHoppa Logo"
                    />
                  </a>
                </div>
                <div className="d-block d-lg-none logo w-49px">
                  <a href="/">
                    <img
                      src="/enc/images/logo_3.png"
                      alt="TripHoppa Logo"
                      width="180"
                      height="80"
                    />
                  </a>
                </div>

                <div className="d-flex">
                  {user && (
                    <div className="d-block d-lg-none">
                      <ProfileDropdown />
                    </div>
                  )}

                  <button
                    type="button"
                    className="nav-toggle-btn a-nav-toggle ms-auto d-block d-lg-none"
                    aria-label="toggle-nav"
                    onClick={toggleMenu}
                  >
                    <span className="nav-toggle nav-toggle-sm">
                      <span className="stick stick-1"></span>
                      <span className="stick stick-2"></span>
                      <span className="stick stick-3"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-lg-6">
              <div className="header__upper--right align-items-center">
                <nav className="navigation">
                  <ul>
                    <li>
                      <a href="/">
                        <RiHome3Fill size={20} /> Home
                      </a>
                    </li>
                    {/* {user && (
                      <li>
                        <a href="/dashboard/overview">
                          <RiDashboardFill size={20} /> Dashboard
                        </a>
                      </li>
                    )} */}
                  </ul>
                </nav>

                {!user ? (
                  <>
                    <a href="/auth/login" className="button p-0">
                      <FontAwesomeIcon
                        className="icon"
                        icon={faUserAstronaut}
                      />{" "}
                      Drive with us{" "}
                    </a>
                    <a href="/auth/login" className="button p-0">
                      <FontAwesomeIcon className="icon" icon={faTaxi} /> Book a
                      Ride{" "}
                    </a>
                  </>
                ) : (
                  <ProfileDropdown />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Responsive menuOpen={menuOpen} toggleMenu={toggleMenu} user={user} />
    </header>
  );
};

export default Navbartwo;
