import React from "react";
import FileUpload from "./FileUpload";

const DriverVerificationForm = ({
  step,
  setStep,
  formData,
  setFormData,
  handleSubmit,
  isSubmitting,
}) => {
  const handleChange = (e) => {
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(updatedFormData);
  };

  const handleFileSelect = (file, fileType) => {
    setFormData((prevData) => ({
      ...prevData,
      [fileType]: file,
    }));
  };

  const handleVehicleImagesSelect = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      vehicleImages: [...prev.vehicleImages, ...files],
    }));
  };

  const handleContinue = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  return (
    <div className="modal-body p-4">
      <form onSubmit={step === 3 ? handleSubmit : handleContinue}>
        {step === 1 && (
          <div className="row gx-4 gy-2">
            <div className="col-md-6">
              <label className="form-label text-secondary">Full Name</label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">Document Type</label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="documentType"
                value="Driver License"
                readOnly
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">
                License Number
              </label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="licenseNumber"
                value={formData.licenseNumber}
                onChange={handleChange}
                placeholder="Enter your license number"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">
                License Expiry Date
              </label>
              <input
                type="date"
                className="form-control p-3 bg-light border-0"
                name="licenseExpiryDate"
                value={formData.licenseExpiryDate}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-md-12">
              <div className="row">
                <FileUpload
                  label="License Front"
                  selectedFile={formData.licenseFront}
                  onFileSelect={(file) =>
                    handleFileSelect(file, "licenseFront")
                  }
                  required
                />
                <FileUpload
                  label="License Back"
                  selectedFile={formData.licenseBack}
                  onFileSelect={(file) => handleFileSelect(file, "licenseBack")}
                  required
                />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="row gx-4 gy-2">
            <div className="col-md-6">
              <label className="form-label text-secondary">Make</label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="make"
                value={formData.make}
                onChange={handleChange}
                placeholder="Enter vehicle make"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">Model</label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="model"
                value={formData.model}
                onChange={handleChange}
                placeholder="Enter vehicle model"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">Year</label>
              <select
                className="form-control p-3 bg-light border-0"
                name="year"
                value={formData.year}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select year
                </option>
                {Array.from({ length: 30 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">Capacity</label>
              <input
                type="number"
                className="form-control p-3 bg-light border-0"
                name="capacity"
                value={formData.capacity}
                onChange={handleChange}
                placeholder="Enter vehicle capacity"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">Plate Number</label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="plateNumber"
                value={formData.plateNumber}
                onChange={handleChange}
                placeholder="Enter vehicle plate number"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">
                Vehicle Images
              </label>
              <input
                type="file"
                className="form-control p-3 bg-light border-0"
                onChange={handleVehicleImagesSelect}
                multiple
                accept="image/*"
                required={formData.vehicleImages.length === 0}
              />

              <div className="small text-muted mt-2">
                Upload multiple images of your vehicle (front, back, sides).
                Maximum 5 images allowed.
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="row gx-4 gy-2">
            <div className="col-md-6">
              <label className="form-label text-secondary">
                Insurance Number
              </label>
              <input
                type="text"
                className="form-control p-3 bg-light border-0"
                name="insuranceNumber"
                value={formData.insuranceNumber}
                onChange={handleChange}
                placeholder="Enter insurance number"
                required
              />
            </div>

            <div className="col-md-6">
              <label className="form-label text-secondary">
                Insurance Expiry Date
              </label>
              <input
                type="date"
                className="form-control p-3 bg-light border-0"
                name="insuranceExpiryDate"
                value={formData.insuranceExpiryDate}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-md-12">
              <div className="row">
                <FileUpload
                  label="Insurance Front"
                  selectedFile={formData.insuranceFront}
                  onFileSelect={(file) =>
                    handleFileSelect(file, "insuranceFront")
                  }
                  required
                />
                <FileUpload
                  label="Insurance Back"
                  selectedFile={formData.insuranceBack}
                  onFileSelect={(file) =>
                    handleFileSelect(file, "insuranceBack")
                  }
                  required
                />
              </div>
            </div>
          </div>
        )}

        <div className="modal-btn mt-4">
          <div className="d-flex justify-content-between gap-4 mt-4">
            {step > 1 && (
              <button
                type="button"
                className="btn btn-light px-5 py-3 fw-semibold"
                style={{
                  backgroundColor: "#ffeafd",
                  color: "#96298d",
                  border: "none",
                }}
                onClick={handleBack}
              >
                Back
              </button>
            )}

            <button
              type="submit"
              className="btn btn-purple text-white px-5 py-3 fw-semibold ms-auto"
              style={{ backgroundColor: "#96298d" }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span>
                  <span
                    className="spinner-border spinner-border-sm text-light me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Submitting...
                </span>
              ) : step === 3 ? (
                "Submit"
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DriverVerificationForm;
