import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { useAuth } from "../../../../context/AuthContext";
import { api, AuthAPi } from "../../../../utils/fetcher";
import { useToast } from "../../../../context/ToastContext";
import DriverVerificationForm from "./DriverVerificationForm";
import "../verifyaccount.css";

const DriverVerificationModal = ({ show, onClose }) => {
  const { user, token } = useAuth();
  const showToast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalClass, setModalClass] = useState("");
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // KYC Info
    fullName: "",
    kycDocumentNumber: "",
    kycDocumentType: "Driver License",
    kycDocument: null,
    kycDocumentBack: null,
    kycDocumentExpiry: "",

    // Driver Info
    userId: null,
    licenseNumber: "",
    licenseExpiryDate: "",
    licenseFront: null,
    licenseBack: null,

    // Vehicle Info
    make: "",
    model: "",
    year: "",
    capacity: "",
    plateNumber: "",

    // Vehicle Images
    vehicleImages: [],

    // Insurance Info
    insuranceNumber: "",
    insuranceExpiryDate: "",
    insuranceFront: null,
    insuranceBack: null,
  });

  useEffect(() => {
    if (user?.uid) {
      setFormData((prev) => ({ ...prev, userId: user.uid }));
    }
  }, [user]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${window.scrollY}px`;
      setModalClass("show");
    } else {
      const timer = setTimeout(() => {
        document.body.style.overflow = "unset";
        document.body.style.position = "static";
        document.body.style.width = "auto";
        const scrollY = parseInt(document.body.style.top || "0") * -1;
        window.scrollTo(0, scrollY);
        document.body.style.top = "";
        setModalClass("hide");
      }, 10);
      return () => clearTimeout(timer);
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    };
  }, [show]);

  const handleClose = () => {
    setFormData({
      fullName: "",
      kycDocumentNumber: "",
      kycDocumentType: "Driver License",
      kycDocument: null,
      kycDocumentBack: null,
      kycDocumentExpiry: "",
      licenseNumber: "",
      licenseExpiryDate: "",
      licenseFront: null,
      licenseBack: null,
      make: "",
      model: "",
      year: "",
      capacity: "",
      plateNumber: "",
      insuranceNumber: "",
      insuranceExpiryDate: "",
      insuranceFront: null,
      insuranceBack: null,
      vehicleImages: [],
    });
    setStep(1);
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      showToast("error", "Please login first!");
      return;
    }

    setIsSubmitting(true);

    try {
      // Update Driver KYC Information
      const kycFormData = {
        fullName: formData.fullName,
        documentNumber: formData.licenseNumber,
        documentType: formData.kycDocumentType,
        documentExpiry: formData.licenseExpiryDate,
      };

      const accountResponse = await AuthAPi.patch(
        "/user/verifyUser",
        kycFormData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!accountResponse.data.success) {
        throw new Error(
          accountResponse.data?.message || "Account verification failed."
        );
      }

      // Submit KYC documents
      const kycData = new FormData();
      kycData.append("kycFront", formData.licenseFront);
      kycData.append("kycBack", formData.licenseBack);

      const kycResponse = await AuthAPi.post("/user/kyc", kycData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!kycResponse.data.success) {
        throw new Error(
          kycResponse.data?.message || "KYC document upload failed."
        );
      }

      // Submit vehicle information
      const vehicleFormData = new FormData();
      vehicleFormData.append("make", formData.make);
      vehicleFormData.append("model", formData.model);
      vehicleFormData.append("year", formData.year);
      vehicleFormData.append("capacity", formData.capacity);
      vehicleFormData.append("plateNumber", formData.plateNumber);
      vehicleFormData.append("insuranceNumber", formData.insuranceNumber);
      vehicleFormData.append(
        "insuranceExpiryDate",
        formData.insuranceExpiryDate
      );

      if (formData.insuranceFront) {
        vehicleFormData.append("insuranceFront", formData.insuranceFront);
      }
      if (formData.insuranceBack) {
        vehicleFormData.append("insuranceBack", formData.insuranceBack);
      }

      // Append vehicle images
      formData.vehicleImages.forEach((image) => {
        vehicleFormData.append("vehicleImages", image);
      });

      // Action For Vehicle
      const vehicleResponse = await AuthAPi.post(
        "/vehicles/registerVehicle",
        vehicleFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Then register the driver with the vehicle ID
      if (vehicleResponse.data.vehicle?._id) {
        const vehicleId = vehicleResponse.data.vehicle._id;
        const driverFormData = new FormData();
        driverFormData.append("licenseNumber", formData.licenseNumber);
        driverFormData.append("licenseExpiryDate", formData.licenseExpiryDate);
        driverFormData.append("userId", formData.userId);

        if (formData.licenseFront) {
          driverFormData.append("licenseFront", formData.licenseFront);
        }
        if (formData.licenseBack) {
          driverFormData.append("licenseBack", formData.licenseBack);
        }

        // Action For Driver
        await api.post(
          `/driver/requestVerification?userId=${formData.userId}&vehicleId=${vehicleId}`,
          driverFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        showToast("success", "Driver verification request sent successfully!");
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      if (error.response?.status === 409) {
        showToast("error", "Driver with this User ID already exists.");
      } else {
        showToast(
          "error",
          error.response?.data?.message ||
            "Failed to submit verification request"
        );
      }

      if (error.response?.status === 403) {
        sessionStorage.removeItem("token");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!show) return null;

  return (
    <div
      id="verification-modal"
      className={`modal d-flex align-items-center justify-content-center shadow-lg ${modalClass}`}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "620px", position: "relative", zIndex: 10000 }}
      >
        <div className="modal-content border-0 shadow">
          <div className="modal-header border-0 px-4 d-flex justify-content-between align-items-start">
            <div>
              <h2 className="mb-1 fw-bold">
                {step === 1
                  ? "Driver Information"
                  : step === 2
                  ? "Vehicle Information & Images"
                  : "Insurance Information"}
              </h2>
              <p className="text-muted mb-0">
                {step === 1
                  ? "Enter your driver details"
                  : step === 2
                  ? "Enter your vehicle details and images"
                  : "Enter insurance details and images"}
              </p>
              <p className="text-muted small mb-0">Step {step} of 3</p>
            </div>
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#ffeafd",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <RxCross1 size={20} color="#96298d" />
            </div>
          </div>

          <DriverVerificationForm
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverVerificationModal;
