import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Shield } from "lucide-react";
import UserVerificationModal from "../../../Authentication/Verification/UserVerification";
import DriverVerificationModal from "../VerificationForm/DriverVerification";
import "../../Verification/verifyaccount.css";
import { useAuth } from "../../../../context/AuthContext";

const VerificationPage = () => {
  const { accountStatus, accountType } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const handleVerify = () => {
    setShowModal(true);
  };

  const buttonDisabled =
    accountStatus === "Approved" || accountStatus === "Pending";

  const buttonStyle = buttonDisabled
    ? {
        backgroundColor: "#d3d3d3",
        color: "#999",
        cursor: "not-allowed",
      }
    : {
        color: "white",
        cursor: "pointer",
      };

  return (
    <>
      <div className="min-vh-100 d-flex bg-white position-relative overflow-hidden">
        {/* Purple curved line */}
        <div
          className="position-absolute top-0 start-0"
          style={{ width: "10rem", height: "10rem" }}
        >
          <div
            className="position-absolute border border-4 rounded-circle"
            style={{
              width: "10rem",
              height: "10rem",
              borderColor: "purple",
              top: "-2rem",
              left: "-5rem",
              opacity: 0.6,
            }}
          ></div>
        </div>

        {/* Orange curved line */}
        <div
          className="position-absolute bottom-0 end-0"
          style={{ width: "10rem", height: "10rem" }}
        >
          <div
            className="position-absolute border border-4 rounded-circle"
            style={{
              width: "10rem",
              height: "10rem",
              borderColor: "orange",
              bottom: "-3rem",
              right: "-3rem",
              opacity: 0.2,
            }}
          ></div>
        </div>

        {/* Main Content */}
        <div
          className="verification_content container"
          style={{ alignSelf: "center" }}
        >
          {/* Logo Section */}
          <div
            style={{ textAlign: "center", marginBottom: "5rem" }}
            className="logo"
          >
            <a href="/get-started">
              <img
                src="/enc/images/logo_3.png"
                alt="logo"
                style={{ width: "200px" }}
              />
            </a>
          </div>

          <div className="text-center">
            <div className="verify-illustration d-inline-block position-relative mb-4">
              <img
                src="/enc/images/verify_id.png"
                alt="verify_image"
                style={{ width: "30rem" }}
              />
            </div>

            <div className="mb-4">
              <h1 className="display-4 fw-bold text-dark fs-2">
                {accountStatus === "Pending"
                  ? "Your account is under verification"
                  : accountStatus === "Approved"
                  ? "Your account is verified"
                  : "Your account is not verified"}
              </h1>
              <p
                className="text-muted fs-7 mx-auto"
                style={{ maxWidth: "40rem" }}
              >
                {accountStatus === "Pending"
                  ? "Please wait for your account to be verified. For any queries, contact us."
                  : accountStatus === "Approved"
                  ? "You can now access all the features."
                  : "Please verify your account to gain access."}
              </p>
            </div>

            <div className="d-flex justify-content-center gap-3 pt-3">
              <button
                className="btn btn-verify-custom p-3 fw-semibold border border-1 d-flex align-items-center gap-2 verify-id"
                onClick={handleVerify}
                disabled={buttonDisabled}
                style={buttonStyle}
              >
                <Shield size={20} />
                <span>Verify ID</span>
              </button>
              <a href="/contact-us">
                <button
                  className="btn p-3 text-white fw-semibold d-flex align-items-center gap-2 contact-us"
                  style={{ backgroundColor: "#96298d" }}
                >
                  Contact Us
                </button>
              </a>
            </div>

            <div className="mt-3 font-semibold">
              <Link to="/get-started" style={{ color: "#96298d" }}>
                Go Back To Home
              </Link>
            </div>
          </div>
        </div>
      </div>

      {accountType === "DRIVER" && (
        <DriverVerificationModal
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      {accountType === "USER" && (
        <UserVerificationModal
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default VerificationPage;