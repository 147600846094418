import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AuthRoutes from "./pages/Authpage/Auth";
import VerificationWarning from "./pages/Verificationpage/Verification";
import Home from "./pages/Homepage/Home";
import Starter from "./pages/Getstarted/Getstarted";
import Contact from "./pages/Contactpage/Contact";
import PrivacyPolicy from "./pages/Privacypolicy/PrivacyPolicy";
import TermsOfUse from "./pages/Termsofusepage/TermsOfUse";
import Preloader from "./components/More/Loader/Preloader";
import { logPageView } from "./index";
import DriverPortal from "./pages/Dashboard/DriverPortal";
import NotFound from "./pages/404";
import { useAuth } from "./context/AuthContext";

const App = () => {
  return <Main />;
};

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/auth/login" />;
};

const Main = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/auth")) {
      setLoading(true);
      logPageView();

      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (!loading) {
      if (location.hash) {
        const sectionID = location.hash.substring(1);
        const sectionElement = document.getElementById(sectionID);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [loading, location]);

  return (
    <>
      {loading && <Preloader />}
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-started" element={<Starter />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/*" element={<AuthRoutes />} />
          <Route
            path="/verify"
            element={
              <ProtectedRoute>
                <VerificationWarning />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                {" "}
                <DriverPortal />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
