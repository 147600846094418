import React, { useState } from "react";
import { Bell } from "lucide-react";
import ProfileDropdown from "./ProfileDropdown";
import { useHeader } from "../../../../context/LayoutContext";
import "../../Layout/layout.css";
import { BsSearch } from "react-icons/bs";

const DashboardHeader = () => {
  const { pageTitle, pageSubtitle } = useHeader();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // You can add search functionality here, for example, filter content based on searchQuery
  };

  return (
    <div className="bg-white px-3 py-3 border-bottom">
      <div className="dashboardheader-container d-flex align-items-center justify-content-between">
        <div className="titles">
          <h1 className="fs-3 fw-bold text-dark mb-1">{pageTitle}</h1>
          <p className="text-secondary mb-0">{pageSubtitle}</p>
        </div>
        <div className="profile-head d-flex">
          <div className="search-bar-container position-relative me-4 d-flex align-items-center">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              aria-label="Search"
            />
            <span className="position-absolute top-50 end-0 translate-middle-y me-3">
              <BsSearch size={22} />
            </span>
          </div>

          <div className="d-flex align-items-center">
            <div className="home me-4 align-items-center">
              <a className="fw-semibold" href="/get-started">
                Home
              </a>
            </div>

            <div className="position-relative me-4">
              <Bell size={24} className="text-secondary" />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                2<span className="visually-hidden">unread notifications</span>
              </span>
            </div>
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
