import React from "react";
import { Outlet } from "react-router-dom";
import AuthCarousel from "./AuthCarousel";

const AuthLayout = () => {
  const slides = [
    {
      imgSrc: "/enc/images/pict.png",
      title: "Make all your trips profitable with TripHoppa",
      description:
        "Transform your travel passion into a lucrative business opportunity. With TripHoppa, every mile driven becomes a chance to earn while exploring new horizons.",
    },
    {
      imgSrc: "/enc/images/pict.png",
      title: "Smart Routes, Smarter Earnings",
      description:
        "Maximize your income with our intelligent route optimization. TripHoppa's advanced matching system connects you with ideal passengers along your planned journey.",
    },
    {
      imgSrc: "/enc/images/pict.png",
      title: "Your Car, Your Schedule, Your Success",
      description:
        "Take control of your earning potential. Whether it's daily commutes or long-distance trips, TripHoppa helps you monetize your regular routes with flexible scheduling.",
    },
    {
      imgSrc: "/enc/images/pict.png",
      title: "Drive Less, Earn More with TripHoppa",
      description:
        "Cut your travel costs while boosting your income. Our platform helps you find perfect passenger matches, turning empty seats into profitable opportunities.",
    },
  ];

  return (
    <div className="h-screen w-full bg-white">
      <div className="h-full row m-0">
        <div className="p-0 col-md-7 h-full d-none d-md-block">
          <AuthCarousel slides={slides} />
        </div>

        {/* Form Section */}
        <div className="auth_form col-12 col-md-5 h-full d-flex align-items-center justify-content-center bg-white">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
