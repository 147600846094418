import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiSteeringWheel } from "react-icons/gi";
import { FaCar } from "react-icons/fa";
import AuthInput from "../AuthInput";
import AuthBtn from "../AuthBtn";
import {
  validateEmail,
  validatePassword,
  validatePhone,
} from "./SignupFormValidation";
import { initialFormData, initialErrors } from "./SignupFormStates";
import "../index.css";
import { api } from "../../../utils/fetcher";
import { useToast } from "../../../context/ToastContext";
import SignupLinks from "./SignupLinks";

const countryPhoneCodes = {
  Kenya: "+254",
  Rwanda: "+250",
  USA: "+1",
  Canada: "+1",
  Ghana: "+233",
  Nigeria: "+234",
};

const SignupForm = () => {
  const [activeTab, setActiveTab] = useState("drive");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [driverData, setDriverData] = useState({
    ...initialFormData,
    accountType: "DRIVER",
  });
  const [passengerData, setPassengerData] = useState({
    ...initialFormData,
    accountType: "USER",
  });
  const [errors, setErrors] = useState(initialErrors);
  const navigate = useNavigate();
  const showToast = useToast();

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const handleChange = (e, formType) => {
    const { name, value } = e.target;

    if (formType === "drive") {
      setDriverData((prev) => ({ ...prev, [name]: value }));
    } else {
      setPassengerData((prev) => ({ ...prev, [name]: value }));
    }

    if (activeTab === "drive") {
      if (name === "country") {
        const countryCode = countryPhoneCodes[value] || "";
        setDriverData((prevData) => ({
          ...prevData,
          [name]: value,
          phone: countryCode,
        }));
      } else if (name === "phone") {
        let phoneValue = value;

        if (!phoneValue.startsWith("+")) {
          phoneValue = "+" + phoneValue;
        }

        phoneValue = phoneValue.replace(/(?!^\+)[^\d\s-]/g, "");

        setDriverData((prevData) => ({
          ...prevData,
          phone: phoneValue,
        }));
      } else {
        setDriverData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      if (name === "country") {
        const countryCode = countryPhoneCodes[value] || "";
        setPassengerData((prevData) => ({
          ...prevData,
          [name]: value,
          phone: countryCode,
        }));
      } else if (name === "phone") {
        let phoneValue = value;

        if (!phoneValue.startsWith("+")) {
          phoneValue = "+" + phoneValue;
        }

        phoneValue = phoneValue.replace(/(?!^\+)[^\d\s-]/g, "");

        setPassengerData((prevData) => ({
          ...prevData,
          phone: phoneValue,
        }));
      } else {
        setPassengerData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  // Clear errors
  const clearError = (name) => {
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  //Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = activeTab === "drive" ? driverData : passengerData;

    if (
      !data.email.trim() ||
      !data.password.trim() ||
      !data.confPassword.trim() ||
      !data.fname.trim() ||
      !data.lname.trim() ||
      !data.country.trim() ||
      !data.phone.trim()
    ) {
      alert("Please fill in all fields");
      return;
    }

    let valid = true;
    const newErrors = { ...initialErrors };

    if (!validateEmail(data.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    }
    if (!validatePassword(data.password)) {
      valid = false;
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }
    if (!validatePhone(data.phone)) {
      valid = false;
      newErrors.phoneNumber = "Invalid phone number format";
    }

    if (data.password !== data.confPassword) {
      valid = false;
      newErrors.confPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    if (!valid) return;

    setLoading(true);

    try {
      const countryCode = countryPhoneCodes[data.country] || "";
      const accountType = activeTab === "drive" ? "DRIVER" : "USER";

      const payload = {
        email: data.email,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        country: data.country,
        phoneNumber: data.phone,
        countryCode,
        accountType,
      };
      const res = await api.post("/user/register", payload);

      if (activeTab === "drive") {
        setDriverData(initialFormData);
      } else {
        setPassengerData(initialFormData);
      }

      showToast(
        "success",
        "Account created successfully! Please verify your email."
      );
      console.log(res);
      if (res.data) {
        const user = res.data.data?.user.user;

        sessionStorage.setItem("token", res.data.data?.refreshToken);
        sessionStorage.setItem("user", JSON.stringify(user));
        navigate("/auth/login");
      }
    } catch (error) {
      if (error.response?.status === 409) {
        showToast(
          "error",
          "This email is already associated with an account. Please Login."
        );
      } else {
        showToast("error", "Failed to create account");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="auth-form min-h-screen flex flex-col items-center justify-center relative"
        style={{ maxWidth: "400px" }}
      >
        {/* Logo Section */}
        <div
          style={{
            textAlign: "center",
            marginBottom: "30px",
          }}
          className="logo"
        >
          <Link to="/get-started">
            <img
              src="/enc/images/logo_3.png"
              alt="logo"
              style={{ width: "200px" }}
            />
          </Link>
        </div>

        {/* Signup Form Section */}
        <div className="w-100 max-w-md">
          <div className="card border-0">
            <div className="card-body">
              <ul className="nav nav-tabs mb-3">
                <li className="nav-item w-50">
                  <button
                    onClick={() => setActiveTab("drive")}
                    className={`nav-link w-100 py-2 px-3 border rounded ${
                      activeTab === "drive" ? "active" : ""
                    }`}
                  >
                    <GiSteeringWheel className="me-1" />
                    Apply to Drive
                  </button>
                </li>
                <li className="nav-item w-50">
                  <button
                    onClick={() => setActiveTab("carpool")}
                    className={`nav-link w-100 py-2 px-3 border rounded  ${
                      activeTab === "carpool" ? "active" : ""
                    }`}
                  >
                    <FaCar className="me-1" />
                    Hop on a Ride
                  </button>
                </li>
              </ul>

              <h2 className="text-center mb-3 fs-6">
                {activeTab === "drive"
                  ? "Start driving a carpool and earn instantly"
                  : "Get member exclusive rewards"}
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="row gx-2">
                  <div className="col-md-6">
                    <AuthInput
                      name="fname"
                      value={
                        activeTab === "drive"
                          ? driverData.fname
                          : passengerData.fname
                      }
                      onChange={(e) => handleChange(e, activeTab)}
                      placeHolder="First Name"
                      type="text"
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="col-md-6">
                    <AuthInput
                      name="lname"
                      value={
                        activeTab === "drive"
                          ? driverData.lname
                          : passengerData.lname
                      }
                      onChange={(e) => handleChange(e, activeTab)}
                      placeHolder="Last Name"
                      type="text"
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div className="mb-2">
                  {" "}
                  <AuthInput
                    name="email"
                    value={
                      activeTab === "drive"
                        ? driverData.email
                        : passengerData.email
                    }
                    onChange={(e) => {
                      clearError("email");
                      handleChange(e, activeTab);
                    }}
                    onFocus={() => clearError("email")}
                    placeHolder="Email"
                    type="text"
                    className="form-control form-control-sm"
                  />
                  {errors.email && (
                    <div className="text-danger" style={{ fontSize: "10px" }}>
                      {errors.email}
                    </div>
                  )}
                </div>

                <div className="mb-2">
                  <AuthInput
                    name="country"
                    value={
                      activeTab === "drive"
                        ? driverData.country
                        : passengerData.country
                    }
                    onChange={(e) => handleChange(e, activeTab)}
                    placeHolder="Country"
                    type="text"
                    className="form-control form-control-sm"
                  />
                </div>

                <div className="mb-2">
                  <AuthInput
                    name="phone"
                    value={
                      activeTab === "drive"
                        ? driverData.phone
                        : passengerData.phone
                    }
                    onChange={(e) => {
                      clearError("phone");
                      handleChange(e, activeTab);
                    }}
                    onFocus={() => clearError("phone")}
                    placeHolder="Phone Number"
                    type="tel"
                    className="form-control form-control-sm"
                  />
                  {errors.phoneNumber && (
                    <div className="text-danger" style={{ fontSize: "10px" }}>
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>

                <div className="mb-2">
                  <AuthInput
                    name="password"
                    value={
                      activeTab === "drive"
                        ? driverData.password
                        : passengerData.password
                    }
                    onChange={(e) => {
                      clearError("password");
                      handleChange(e, activeTab);
                    }}
                    onFocus={() => clearError("password")}
                    placeHolder="Password"
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                    showPassword={showPassword}
                    isPassword={true}
                    className="form-control form-control-sm"
                  />
                  {errors.password && (
                    <div className="text-danger" style={{ fontSize: "10px" }}>
                      {errors.password}
                    </div>
                  )}
                </div>

                <div className="mb-2">
                  <AuthInput
                    name="confPassword"
                    value={
                      activeTab === "drive"
                        ? driverData.confPassword
                        : passengerData.confPassword
                    }
                    onChange={(e) => {
                      clearError("confPassword");
                      handleChange(e, activeTab);
                    }}
                    onFocus={() => clearError("confPassword")}
                    placeHolder="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    handleShowPassword={handleShowPassword}
                    showPassword={showPassword}
                    isPassword={true}
                    className="form-control form-control-sm"
                  />
                  {errors.confPassword && (
                    <div className="text-danger" style={{ fontSize: "10px" }}>
                      {errors.confPassword}
                    </div>
                  )}
                </div>

                <div className="small text-muted mb-3 fs-8">
                  By proceeding, you agree to the{" "}
                  <Link to="/terms" className="text-primary">
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy" className="text-primary">
                    Privacy Policy
                  </Link>
                </div>

                <AuthBtn
                  text={
                    activeTab === "drive" ? "Become a Driver" : "Sign up today"
                  }
                  disabled={loading}
                  loading={loading}
                  className="btn-sm w-100"
                />

                <SignupLinks />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
