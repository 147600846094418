import React from "react";
// import $ from "jquery";
import "../About/about.css";

const About = () => {
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const element = document.querySelector("#counter");
  //     const position = element.getBoundingClientRect();

  //     // checking whether fully visible
  //     if (position.top >= 0 && position.bottom <= window.innerHeight) {
  //       $(".counter-value").each(function () {
  //         var $this = $(this);
  //         var countTo = $this.attr("data-count");
  //         $({
  //           countNum: $this.text(),
  //         }).animate(
  //           {
  //             countNum: countTo,
  //           },
  //           {
  //             duration: 2000,
  //             easing: "swing",
  //             step: function () {
  //               $this.text(Math.floor(this.countNum));
  //             },
  //             complete: function () {
  //               $this.text(this.countNum);
  //             },
  //           }
  //         );
  //       });
  //     } else {
  //       $(".counter-value").each(function () {
  //         var $this = $(this);
  //         var countTo = 0;
  //         $({
  //           countNum: $this.text(),
  //         }).animate(
  //           {
  //             countNum: countTo,
  //           },
  //           {
  //             duration: 100,
  //             easing: "swing",
  //             step: function () {
  //               $this.text(Math.floor(this.countNum));
  //             },
  //             complete: function () {
  //               $this.text(this.countNum);
  //             },
  //           }
  //         );
  //       });
  //     }
  //   };

  //   // Add event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Remove event listener on cleanup
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <section className="row_am about_app_section">
      {/* <!-- container start --> */}
      <div className="container">
        {/* <!-- row start --> */}
        <div className="row">
          <div className="col-lg-6">
            {/* <!-- about images --> */}
            <div
              className="about_img"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="frame_img">
                <img
                  className="moving_position_animatin w-[25rem]"
                  src="/enc/images/mockups/4.png"
                  alt="img"
                  style={{ width: 25 + "rem" }}
                />
              </div>
              <div className="screen_img">
                <img
                  className="moving_animation"
                  src="/enc/images/mockups/component.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            {/* <!-- about text --> */}
            <div className="about_text">
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                {/* <!-- h2 --> */}
                <h2>
                  Why <span>TripHoppa?</span>
                </h2>

                {/* <!-- p --> */}
                <p>
                  TripHoppa is your go-to carpooling platform, connecting
                  drivers and passengers for safe, affordable, and eco-friendly
                  travel. Whether you're commuting or planning a long-distance
                  trip, we make it easy to share rides and save on travel costs.
                </p>
              </div>

              {/* <!-- UL --> */}
              {/* <ul
                className="app_statstic"
                id="counter"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <li>
                  <div className="icon">
                    <img src="/enc/images/download.png" alt="img" />
                  </div>
                  <div className="text">
                    <p>
                      <span className="counter-value" data-count="0">
                        0
                      </span>
                      <span>M+</span>
                    </p>
                    <p>Users</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/enc/images/followers.png" alt="img" />
                  </div>
                  <div className="text">
                    <p>
                      <span className="counter-value" data-count="0">
                        0{" "}
                      </span>
                      <span>M+</span>
                    </p>
                    <p>Drivers</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/enc/images/reviews.png" alt="img" />
                  </div>
                  <div className="text">
                    <p>
                      <span className="counter-value" data-count="0">
                        0
                      </span>
                      <span>+</span>
                    </p>
                    <p>Reviews</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <img src="/enc/images/countries.png" alt="img" />
                  </div>
                  <div className="text">
                    <p>
                      <span className="counter-value" data-count="0">
                        0
                      </span>
                      <span>+</span>
                    </p>
                    <p>Countries</p>
                  </div>
                </li>
              </ul> */}
              {/* <!-- UL end --> */}
              {/* <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeq4wAtgjjZc0PkK8qBm5MqFsKLKpkCa2N4oPlozcGYoRBpUQ/viewform"
                className="btn puprple_btn"
                data-aos="fade-in"
                data-aos-duration="1500"
                target="_blank"
                rel="noreferrer"
              >
                Book Now
              </a> */}
            </div>
          </div>
        </div>
        {/* <!-- row end --> */}
      </div>
      {/* <!-- container end --> */}
    </section>
  );
};

export default About;
