import React, { createContext, useEffect, useState, useCallback } from "react";
import Preloader from "../components/More/Loader/Preloader";
import { AuthAPi, api } from "../utils/fetcher";

const AuthContext = createContext({
  user: null,
  token: null,
  accountStatus: null,
  accountType: null,
  setUser: () => {},
  setToken: () => {},
  updateAccountStatus: () => {},
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [init, setInit] = useState(true);

  const updateAccountStatus = useCallback(async (newStatus) => {
    try {
      const { data } = await AuthAPi.patch(`/user/updateAccountStatus`, {
        accountStatus: newStatus,
      });

      if (data.status) {
        setAccountStatus(newStatus);
        sessionStorage.setItem("accountStatus", newStatus);

        setUser((prevUser) => ({
          ...prevUser,
          accountStatus: newStatus,
        }));

        return true;
      }
      return false;
    } catch (error) {
      console.error("Error updating account status:", error);
      return false;
    }
  }, []);

  const checkDriverStatus = useCallback(
    async (userId, currentUser) => {
      try {
        const { data } = await api.get("/driver/getDriverInfo", {
          params: { driverId: userId },
        });

        if (data.status && data.data) {
          const statusMapping = {
            Approved: "Approved",
            Pending: "Pending",
            Rejected: "Rejected",
            Banned: "Banned",
          };

          const newStatus =
            statusMapping[data.data.verificationStatus] || "Pending";
          return await updateAccountStatus(newStatus);
        } else {
          const fallbackStatus = currentUser?.accountStatus || "Pending";
          return await updateAccountStatus(fallbackStatus);
        }
      } catch (error) {
        console.error("Error fetching driver status:", error);
        const fallbackStatus = currentUser?.accountStatus || "";
        return await updateAccountStatus(fallbackStatus);
      }
    },
    [updateAccountStatus]
  );

  const checkPassengerStatus = useCallback(
    async (currentUser) => {
      const statusMapping = {
        "": "",
        Pending: "Pending",
        Approved: "Approved",
      };

      const newStatus = statusMapping[currentUser?.accountStatus] || "";
      return await updateAccountStatus(newStatus);
    },
    [updateAccountStatus]
  );

  const getProfile = useCallback(async () => {
    try {
      const storedToken = sessionStorage.getItem("token");
      if (!storedToken) {
        setInit(false);
        return null;
      }

      const { data } = await AuthAPi.get("/user/getCurrentUser");

      if (data?.data?.user) {
        const currentUser = data.data.user;
        setUser(currentUser);
        setAccountType(currentUser.accountType);

        // Check status based on account type
        if (currentUser.accountType === "DRIVER") {
          await checkDriverStatus(currentUser.uid, currentUser);
        } else if (currentUser.accountType === "USER") {
          await checkPassengerStatus(currentUser);
        }
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    } finally {
      setInit(false);
    }
  }, [checkDriverStatus, checkPassengerStatus]);

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      getProfile();
    } else {
      setInit(false);
    }
  }, [getProfile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        accountStatus,
        accountType,
        setUser,
        setToken,
        updateAccountStatus,
      }}
    >
      {init ? <Preloader /> : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
