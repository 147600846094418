import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Header/header.css";
import { RiHome3Fill } from "react-icons/ri";
import {
  faTaxi,
  faTimes,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

const Responsive = ({ menuOpen, toggleMenu, user }) => {
  return (
    <section className={`collapse responsive-menu ${menuOpen ? "open" : ""}`}>
      <div className="rep-header">
        <div className="logo">
          <a href="/">
            <img
              src="/enc/images/logo_3.png"
              width="180"
              height="80"
              alt="TripHoppa Logo"
            />
          </a>
        </div>
        <button
          type="button"
          title="Close Menu"
          className="close-menu"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="navbar-menu" id="navbarSupportedContent1">
        <div className="header__upper--right flex-column">
          <nav className="navigation pt-3">
            <ul>
              <li>
                <a href="/">
                  <RiHome3Fill size={20} /> Home
                </a>
              </li>
              {/* {user && (
                <li>
                  <a href="/dashboard/overview">
                    <RiDashboardFill size={20} /> Dashboard
                  </a>
                </li>
              )} */}
            </ul>
          </nav>
          {!user && (
            <>
              <a href="/auth/login" className="button p-0">
                <FontAwesomeIcon className="icon" icon={faUserAstronaut} />{" "}
                Drive with us{" "}
              </a>
              <a href="/auth/login" className="button p-0">
                <FontAwesomeIcon className="icon" icon={faTaxi} /> Book a Ride{" "}
              </a>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Responsive;
