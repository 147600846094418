import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import "../../DriversPortal/Verification/verifyaccount.css";
import { useAuth } from "../../../context/AuthContext";
import { AuthAPi, api } from "../../../utils/fetcher";
import { useToast } from "../../../context/ToastContext";

import {
  UserInfoStep,
  VehicleInfoStep,
  InsuranceInfoStep,
} from "./VerificatonSteps";

const defaultUserFormData = {
  fullName: "",
  accountType: "",
  kycDocumentNumber: "",
  kycDocumentType: "National ID",
  kycDocument: null,
  kycDocumentBack: null,
  kycDocumentExpiry: "",
};

const defaultDriverFormData = {
  // Driver Info
  userId: null,
  licenseNumber: "",
  licenseExpiryDate: "",
  licenseFront: null,
  licenseBack: null,

  // Vehicle Info
  make: "",
  model: "",
  year: "",
  capacity: "",
  plateNumber: "",
  vehicleImages: [],

  // Insurance Info
  insuranceNumber: "",
  insuranceExpiryDate: "",
  insuranceFront: null,
  insuranceBack: null,
};

const UserVerificationModal = ({ show, onClose }) => {
  const { user, token } = useAuth();
  const showToast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalClass, setModalClass] = useState("");
  const [step, setStep] = useState(1);
  const [isDriver, setIsDriver] = useState(false);
  const [formData, setFormData] = useState({
    ...defaultUserFormData,
    ...defaultDriverFormData,
  });

  useEffect(() => {
    if (user?.uid) {
      setFormData((prev) => ({ ...prev, userId: user.uid }));
    }
  }, [user]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${window.scrollY}px`;
      setModalClass("show");
    } else {
      const timer = setTimeout(() => {
        document.body.style.overflow = "unset";
        document.body.style.position = "static";
        document.body.style.width = "auto";
        const scrollY = parseInt(document.body.style.top || "0") * -1;
        window.scrollTo(0, scrollY);
        document.body.style.top = "";
        setModalClass("hide");
      }, 10);
      return () => clearTimeout(timer);
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    };
  }, [show]);

  const handleLicenseInfo = () => {
    if (isDriver) {
      setFormData((prev) => ({
        ...prev,
        licenseNumber: prev.kycDocumentNumber,
        licenseFront: prev.kycDocument,
        licenseBack: prev.kycDocumentBack,
        licenseExpiryDate: prev.kycDocumentExpiry,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };

      if (name === "kycDocumentType") {
        if (value === "Driver License") {
          setIsDriver(true);
        } else {
          setIsDriver(false);
        }
      }

      return updatedData;
    });
  };

  const handleClose = () => {
    setFormData({ ...defaultUserFormData, ...defaultDriverFormData });
    setStep(1);
    setIsDriver(false);
    onClose();
  };

  const handleFileSelect = (file, fileType) => {
    setFormData((prev) => ({ ...prev, [fileType]: file }));
  };

  const handleVehicleImagesSelect = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      vehicleImages: [...prev.vehicleImages, ...files],
    }));
  };

  const handleBack = () => setStep((prev) => prev - 1);
  const handleContinue = () => setStep((prev) => prev + 1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleLicenseInfo();
    if (!token) {
      showToast("error", "Please login first!");
      return;
    }

    setIsSubmitting(true);

    try {
      if (isDriver) {
        await AuthAPi.patch(
          "/user/updateAccountType",
          { accountType: "DRIVER" },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      const userFormData = {
        fullName: formData.fullName,
        documentNumber: formData.kycDocumentNumber,
        documentType: formData.kycDocumentType,
        documentExpiry: formData.kycDocumentExpiry,
      };

      const accountResponse = await AuthAPi.patch(
        "/user/verifyUser",
        userFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(accountResponse.data);

      if (!accountResponse.data.success) {
        throw new Error(
          accountResponse.data?.message || "Account verification failed."
        );
      }

      // Submit documents
      const kycData = new FormData();
      kycData.append("kycFront", formData.kycDocument);
      kycData.append("kycBack", formData.kycDocumentBack);

      const kycResponse = await AuthAPi.post("/user/kyc", kycData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!kycResponse.data.success) {
        throw new Error(
          kycResponse.data?.message || "KYC document upload failed."
        );
      }

      // If user is becoming a driver, submit driver info
      if (isDriver && step === 3) {
        // Submit vehicle info first
        const vehicleFormData = new FormData();
        Object.entries(formData)
          .filter(([key]) =>
            [
              "make",
              "model",
              "year",
              "capacity",
              "plateNumber",
              "insuranceNumber",
              "insuranceExpiryDate",
            ].includes(key)
          )
          .forEach(([key, value]) => vehicleFormData.append(key, value));

        if (formData.insuranceFront) {
          vehicleFormData.append("insuranceFront", formData.insuranceFront);
        }
        if (formData.insuranceBack) {
          vehicleFormData.append("insuranceBack", formData.insuranceBack);
        }
        formData.vehicleImages.forEach((image) => {
          vehicleFormData.append("vehicleImages", image);
        });

        // Action For Vehicle
        const vehicleResponse = await AuthAPi.post(
          "/vehicles/registerVehicle",
          vehicleFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Then submit driver info with the vehicle ID
        if (vehicleResponse.data.vehicle?._id) {
          const vehicleId = vehicleResponse.data.vehicle._id;
          const driverFormData = new FormData();
          driverFormData.append("licenseNumber", formData.licenseNumber);
          driverFormData.append(
            "licenseExpiryDate",
            formData.licenseExpiryDate
          );
          driverFormData.append("userId", formData.userId);
          if (formData.licenseFront) {
            driverFormData.append("licenseFront", formData.licenseFront);
          }
          if (formData.licenseBack) {
            driverFormData.append("licenseBack", formData.licenseBack);
          }

          // Action For Driver
          await api.post(
            `/driver/requestVerification?userId=${formData.userId}&vehicleId=${vehicleId}`,
            driverFormData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        }
      }

      showToast("success", "Verification request submitted successfully!");
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      showToast(
        "error",
        error.response?.data?.message || "Failed to submit verification request"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return "User Verification";
      case 2:
        return "Vehicle Information";
      case 3:
        return "Insurance Information";
      default:
        return "";
    }
  };

  const getStepDescription = () => {
    switch (step) {
      case 1:
        return "Complete your KYC verification details";
      case 2:
        return "Enter your vehicle details and images";
      case 3:
        return "Enter insurance details and images";
      default:
        return "";
    }
  };

  const getButtonText = () => {
    if (isSubmitting) return "Submitting...";
    if (step === 1) return "Submit";
    if (step > 1) {
      if (step === 3) return "Submit";
      return "Continue";
    }
    return "Submit";
  };

  const handleButtonClick = (e) => {
    if (step === 1) {
      handleSubmit(e);
    } else if (step === 3) {
      handleSubmit(e);
    } else {
      handleContinue();
    }
  };

  if (!show) return null;

  return (
    <div
      id="verification-modal"
      className={`modal d-flex align-items-center justify-content-center shadow-lg ${modalClass}`}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        height: "100vh",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "620px", position: "relative", zIndex: 10000 }}
      >
        <div className="modal-content border-0 shadow">
          <div className="modal-header border-0 px-4 d-flex justify-content-between align-items-start">
            <div>
              <h2 className="mb-1 fw-bold">{getStepTitle()}</h2>
              <p className="text-muted mb-0">{getStepDescription()}</p>
              {step > 1 && (
                <p className="text-muted small mb-0">Step {step} of 3</p>
              )}
            </div>
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#ffeafd",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <RxCross1 size={20} color="#96298d" />
            </div>
          </div>

          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {step === 1 && (
                <UserInfoStep
                  formData={formData}
                  handleChange={handleChange}
                  handleFileSelect={handleFileSelect}
                />
              )}

              {step === 2 && (
                <VehicleInfoStep
                  formData={formData}
                  handleChange={handleChange}
                  handleVehicleImagesSelect={handleVehicleImagesSelect}
                />
              )}

              {step === 3 && (
                <InsuranceInfoStep
                  formData={formData}
                  handleChange={handleChange}
                  handleFileSelect={handleFileSelect}
                />
              )}

              <div className="modal-btn mt-4">
                <div className="d-flex justify-content-between gap-4 mt-4">
                  {step > 1 && (
                    <button
                      type="button"
                      className="btn btn-light px-5 py-3 fw-semibold"
                      style={{
                        backgroundColor: "#ffeafd",
                        color: "#96298d",
                        border: "none",
                      }}
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}

                  <div className="ms-auto d-flex align-items-center gap-3">
                    {step === 1 && isDriver && (
                      <button
                        type="button"
                        className="btn px-4 py-3"
                        onClick={handleContinue}
                        style={{
                          border: "2px solid transparent",
                          color: "#96298d",
                          backgroundColor: "transparent",
                          transition: "all 0.3s ease-in-out",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.borderColor = "#96298d";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.borderColor = "transparent";
                        }}
                      >
                        Become a Driver
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-purple text-white px-5 py-3 fw-semibold"
                      style={{ backgroundColor: "#96298d" }}
                      onClick={handleButtonClick}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          <span
                            className="spinner-border spinner-border-sm text-light me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Submitting...
                        </span>
                      ) : (
                        getButtonText()
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVerificationModal;
