import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { LogOut, Menu, X } from "lucide-react";
import { BsBarChart, BsEnvelope } from "react-icons/bs";
import { GoVerified } from "react-icons/go";
import { BiTrip } from "react-icons/bi";
import { AiOutlineCar } from "react-icons/ai";
import { TbReport } from "react-icons/tb";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { PiStarFourFill, PiNumberCircleOneFill } from "react-icons/pi";
import { StatusButton } from "../../Verification/VerificationForm/VerificationButton";
import "../dashboard.css";

const Sidebar = () => {
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const location = useLocation();

  const isActive = (path, exact = true) => {
    return exact
      ? location.pathname === path
      : location.pathname.startsWith(path);
  };

  const toggleDropdown = (label) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("accountStatus");
  };

  const mainNavItems = [
    {
      path: "/dashboard/overview",
      icon: <BsBarChart size={22} className="me-3" />,
      label: "Overview",
    },
    {
      type: "dropdown",
      icon: <BiTrip size={22} className="me-3" />,
      label: "Trips",
      items: [
        {
          path: "/dashboard/trips/carpool",
          icon: <PiStarFourFill className="me-2" />,
          label: "Carpool",
          indicator: <PiNumberCircleOneFill className="align-self-right" />,
        },
        {
          path: "/dashboard/trips/parcel",
          icon: <PiStarFourFill className="me-2" />,
          label: "Parcel",
          indicator: <PiNumberCircleOneFill className="align-self-right" />,
        },
      ],
    },
    {
      path: "/dashboard/verification",
      icon: <GoVerified size={22} className="me-3" />,
      label: "Verification",
    },
    {
      type: "dropdown",
      icon: <AiOutlineCar size={22} className="me-3" />,
      label: "Requests",
      items: [
        {
          path: "/dashboard/requests/carpool",
          icon: <PiStarFourFill className="me-2" />,
          label: "Carpool",
          indicator: <PiNumberCircleOneFill className="align-self-right" />,
        },
        {
          path: "/dashboard/requests/parcel",
          icon: <PiStarFourFill className="me-2" />,
          label: "Parcel",
          indicator: <PiNumberCircleOneFill className="align-self-right" />,
        },
      ],
    },
    {
      path: "/dashboard/reports",
      icon: <TbReport size={22} className="me-3" />,
      label: "Reports",
    },
    {
      path: "/dashboard/settings",
      icon: <HiOutlineCog6Tooth size={22} className="me-3" />,
      label: "Settings",
    },
  ];

  const bottomNavItems = [
    {
      path: "/contact-us",
      icon: <BsEnvelope size={22} className="me-3" />,
      label: "Contact Us",
    },
    {
      path: "/auth/login",
      icon: <LogOut size={22} className="me-3" />,
      label: "Logout",
      action: logout,
    },
  ];

  const renderNavItem = (item) => {
    if (item.type === "dropdown") {
      return (
        <li className="nav-item" key={item.label}>
          <button
            className="btn btn-link nav-link nav-link-custom d-flex align-items-center justify-content-between fw-semibold"
            onClick={() => toggleDropdown(item.label)}
            style={{ width: "100%" }}
          >
            <div className="d-flex align-items-center">
              {item.icon} {item.label}
            </div>
            {openDropdowns[item.label] ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          {openDropdowns[item.label] && (
            <ul className="nav flex-column ms-4">
              {item.items.map((subItem) => (
                <li className="nav-item" key={subItem.path}>
                  <NavLink
                    to={subItem.path}
                    className={`nav-link nav-link-custom ms-2 d-flex align-items-center justify-content-between fw-semibold ${
                      isActive(subItem.path) ? "active" : ""
                    }`}
                    onClick={() => setIsMobileOpen(false)}
                  >
                    <div>
                      {subItem.icon} {subItem.label}
                    </div>
                    <div>{subItem.indicator}</div>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    }

    return (
      <li className="nav-item" key={item.path}>
        <NavLink
          to={item.path}
          className={`nav-link nav-link-custom d-flex align-items-center fw-semibold ${
            isActive(item.path, true) ? "active" : ""
          }`}
          onClick={() => setIsMobileOpen(false)}
        >
          {item.icon} {item.label}
        </NavLink>
      </li>
    );
  };

  return (
    <>
      {/* Mobile Toggle Button */}
      <button
        className="btn btn-link d-lg-none position-fixed top-0 end-0 m-2 z-3 p-2 bg-white rounded-2 shadow-sm border-0"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
        style={{
          width: "40px",
          height: "40px",
          padding: "8px",
          color: "#96298d",
        }}
      >
        {isMobileOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Sidebar */}
      <div
        className={`sidebar bg-white border-end position-fixed ${
          isMobileOpen ? "open" : ""
        } d-lg-block`}
        style={{
          width: "250px",
          height: "100vh",
          zIndex: 2,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Sidebar Content */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            {/* Logo */}
            <div className="logo mb-4 p-3">
              <a href="/get-started">
                <img
                  src="/enc/images/logo_3.png"
                  alt="logo"
                  style={{ width: "150px" }}
                />
              </a>
            </div>

            {/* Main Nav Items */}
            <ul className="nav flex-column px-3 gap-2">
              {mainNavItems.map(renderNavItem)}
            </ul>

            {/* Verification Button */}
            <div className="p-3">
              <StatusButton className="w-100 d-flex align-items-center justify-content-center fw-semibold" />
            </div>
          </div>

          {/* Bottom Nav Items */}
          <div style={{ marginTop: "auto", paddingBottom: "10px" }}>
            <ul className="nav flex-column px-3 gap-2 pt-3">
              {bottomNavItems.map((item) =>
                item.label === "Logout" ? (
                  <li className="nav-item" key={item.label}>
                    <a
                      href={item.path}
                      onClick={item.action}
                      className="nav-link d-flex align-items-center text-danger"
                      style={{ fontWeight: "700" }}
                    >
                      {item.icon}
                      {item.label}
                    </a>
                  </li>
                ) : (
                  renderNavItem(item)
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
