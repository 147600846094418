import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import "../dashboard.css";

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { user } = useAuth();

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("accountStatus");
    window.location.href = "/auth/login";
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="profile-dropdown dropdown" ref={dropdownRef}>
      <button
        className="btn btn-link dropdown-toggle d-flex align-items-center text-decoration-none fw-semibold text-black"
        type="button"
        id="profileDropdown"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
      >
        <img
          src={
            user?.profileImage?.trim()
              ? user.profileImage
              : "https://img.icons8.com/nolan/64/user-default.png"
          }
          alt="profile_img"
          className="profile-image me-2"
          onError={(e) =>
            (e.currentTarget.src =
              "https://img.icons8.com/nolan/64/user-default.png")
          }
        />
        <span className="profile-user">{user?.fName || "Guest"}</span>
      </button>

      <ul
        className={`dropdown-menu dropdown-menu-end ${isOpen ? "show" : ""}`}
        aria-labelledby="profileDropdown"
      >
        <li className="d-lg-none">
          <Link className="dropdown-item" to="/get-started">
            Home
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/dashboard/profile">
            Profile
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/dashboard/settings">
            Settings
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <button className="dropdown-item text-danger" onClick={logout}>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
