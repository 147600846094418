import axios from "axios";

const getApiBaseUrl = () => {
  const hostname = window.location.hostname;

  // For test environment based on the hostname
  if (hostname.includes("triphoppa-web-app-test")) {
    return "https://triphoppa-web-app-test-0cea860ef71d.herokuapp.com/api/v1";
  }

  // For production environment
  if (process.env.NODE_ENV === "production") {
    return "https://triphoppa-web-app-451eed876a4d.herokuapp.com/api/v1";
  }

  // Default local development API
  return process.env.REACT_APP_API_URL || "http://localhost:3000/api/v1";
};

export const api = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

export const AuthAPi = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  },
});

// Helper function to handle API response errors
export const getResError = (error) => {
  if (!error) return "Something Went Wrong";
  const isNetError = error?.message?.includes("Network Error");
  if (isNetError) return "Network Error";
  return (
    error?.response?.data?.error ||
    error?.response?.data?.message ||
    error?.message ||
    "Something Went Wrong"
  );
};
