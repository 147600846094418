import React, { useState, useEffect } from "react";
import { Upload } from "lucide-react";

const FileUpload = ({ label, selectedFile, onFileSelect, required }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      generatePreview(selectedFile);
    }
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [selectedFile, preview]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileSelect(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileSelect(file);
    }
  };

  const generatePreview = (file) => {
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  return (
    <div className="col-md-6">
      <label className="form-label text-secondary">
        {label}
        {required && <span className="text-danger ms-1">*</span>}
      </label>
      <div
        className="rounded-3 p-2 text-center"
        style={{
          border: "2px dashed #96298d",
          backgroundColor: "#ffeafd",
          cursor: "pointer",
          minHeight: "180px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => document.getElementById(`${label}-fileInput`).click()}
      >
        {preview ? (
          <div className="w-full">
            <img
              src={preview}
              alt="Preview"
              className="mx-auto"
              style={{
                maxWidth: "100%",
                maxHeight: "150px",
                objectFit: "contain",
                margin: "6px 0",
              }}
            />
            <p
              className="mb-0 mt-2"
              style={{ color: "#96298d", fontSize: "12px" }}
            >
              {selectedFile?.name || "Image uploaded"}
            </p>
          </div>
        ) : (
          <>
            <Upload className="text-purple mb-2" size={24} color="#96298d" />
            <p className="mb-0" style={{ color: "#96298d", fontSize: "12px" }}>
              Drag & Drop or click to add image
            </p>
          </>
        )}
        <input
          id={`${label}-fileInput`}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          required={required && !selectedFile}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default FileUpload;
