import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import VerificationPage from "../../components/DriversPortal/Verification/Warnings/VerificationWarning";

const VerificationWarning = () => {
  const navigate = useNavigate();
  const { accountStatus } = useAuth();

  useEffect(() => {
    if (accountStatus === "Approved") {
      navigate("/get-started");
    }
  }, [accountStatus, navigate]);

  return (
    <>
      <VerificationPage />
    </>
  );
};

export default VerificationWarning;
