import React from "react";
import { Link } from "react-router-dom";

const LoginLinks = () => {
  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <Link to="/forgot-password" className="text-decoration-none">
          <small className="text-muted fw-semibold">Forgot password?</small>
        </Link>
      </div>

      <div className="text-center mt-4">
        <p className="text-muted">
          Don't have an account?{" "}
          <Link to="/auth/signup" className="text-decoration-none fw-semibold">
            Register
          </Link>
        </p>
      </div>
    </>
  );
};

export default LoginLinks;
