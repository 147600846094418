import FileUpload from "./FileUpload";

// UserInfoStep.jsx
const UserInfoStep = ({ formData, handleChange, handleFileSelect }) => (
  <div className="row gx-4 gy-2">
    <div className="col-md-6">
      <label className="form-label text-secondary">Full Name</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        placeholder="Enter your full name"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Document Type</label>
      <select
        className="form-select p-3 bg-light border-0"
        name="kycDocumentType"
        value={formData.kycDocumentType}
        onChange={handleChange}
        required
      >
        <option value="National ID">National ID</option>
        <option value="Passport">Passport</option>
        <option value="Driver License">Driver License</option>
      </select>
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Document Number</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="kycDocumentNumber"
        value={formData.kycDocumentNumber}
        onChange={handleChange}
        placeholder="Enter your KYC document number"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Document Expiry Date</label>
      <input
        type="date"
        className="form-control p-3 bg-light border-0"
        name="kycDocumentExpiry"
        value={formData.kycDocumentExpiry}
        onChange={handleChange}
        required
      />
    </div>

    <div className="col-md-12">
      <div className="row">
        <FileUpload
          label="Upload Front Document"
          selectedFile={formData.kycDocument}
          onFileSelect={(file) => handleFileSelect(file, "kycDocument")}
          required
        />
        <FileUpload
          label="Upload Back Document"
          selectedFile={formData.kycDocumentBack}
          onFileSelect={(file) => handleFileSelect(file, "kycDocumentBack")}
          required
        />
      </div>
    </div>
  </div>
);

// VehicleInfoStep.jsx
const VehicleInfoStep = ({
  formData,
  handleChange,
  handleVehicleImagesSelect,
}) => (
  <div className="row gx-4 gy-2">
    <div className="col-md-6">
      <label className="form-label text-secondary">Make</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="make"
        value={formData.make}
        onChange={handleChange}
        placeholder="Enter vehicle make"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Model</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="model"
        value={formData.model}
        onChange={handleChange}
        placeholder="Enter vehicle model"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Year</label>
      <select
        className="form-control p-3 bg-light border-0"
        name="year"
        value={formData.year}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select year
        </option>
        {Array.from({ length: 30 }, (_, i) => {
          const year = new Date().getFullYear() - i;
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
      </select>
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Capacity</label>
      <input
        type="number"
        className="form-control p-3 bg-light border-0"
        name="capacity"
        value={formData.capacity}
        onChange={handleChange}
        placeholder="Enter vehicle capacity"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Plate Number</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="plateNumber"
        value={formData.plateNumber}
        onChange={handleChange}
        placeholder="Enter vehicle plate number"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Vehicle Images</label>
      <input
        type="file"
        className="form-control p-3 bg-light border-0"
        onChange={handleVehicleImagesSelect}
        multiple
        accept="image/*"
        required={formData.vehicleImages.length === 0}
      />
      <div className="small text-muted mt-2">
        Upload multiple images of your vehicle (front, back, sides). Maximum 5
        images allowed.
      </div>
    </div>
  </div>
);

// InsuranceInfoStep.jsx
const InsuranceInfoStep = ({ formData, handleChange, handleFileSelect }) => (
  <div className="row gx-4 gy-2">
    <div className="col-md-6">
      <label className="form-label text-secondary">Insurance Number</label>
      <input
        type="text"
        className="form-control p-3 bg-light border-0"
        name="insuranceNumber"
        value={formData.insuranceNumber}
        onChange={handleChange}
        placeholder="Enter insurance number"
        required
      />
    </div>

    <div className="col-md-6">
      <label className="form-label text-secondary">Insurance Expiry Date</label>
      <input
        type="date"
        className="form-control p-3 bg-light border-0"
        name="insuranceExpiryDate"
        value={formData.insuranceExpiryDate}
        onChange={handleChange}
        required
      />
    </div>

    <div className="col-md-12">
      <div className="row">
        <FileUpload
          label="Insurance Front"
          selectedFile={formData.insuranceFront}
          onFileSelect={(file) => handleFileSelect(file, "insuranceFront")}
          required
        />
        <FileUpload
          label="Insurance Back"
          selectedFile={formData.insuranceBack}
          onFileSelect={(file) => handleFileSelect(file, "insuranceBack")}
          required
        />
      </div>
    </div>
  </div>
);

export { UserInfoStep, VehicleInfoStep, InsuranceInfoStep };
