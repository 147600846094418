import React from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./index.css";

const AuthInput = ({
  name,
  type,
  onChange,
  onFocus,
  value,
  placeHolder,
  showPassword,
  handleShowPassword,
  isPassword,
}) => {
  return (
    <div className="position-relative w-100">
      <input
        autoComplete="off"
        className="form-control form-control-sm"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        type={type}
        placeholder={placeHolder}
        required
      />
      {isPassword && (
        <button
          type="button"
          className="btn position-absolute end-0 top-50 translate-middle-y bg-transparent border-0"
          onClick={handleShowPassword}
        >
          {showPassword ? (
            <BsEye className="text-secondary" />
          ) : (
            <BsEyeSlash className="text-secondary" />
          )}
        </button>
      )}
    </div>
  );
};

export default AuthInput;
