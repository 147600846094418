import React from "react";
import "../Hero/hero.css";

const Herocta = () => {
  return (
    <section className="hero-area-v-2 p-0 dark-overlay-3">
      <video autoPlay loop muted playsInline id="myVideo" width="100">
        <source src="/assets/bg-video-2.mp4" type="video/mp4" /> Your browser
        does not support HTML5 video.
      </video>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="hero-area-wrap">
              <div className="hero-area-left">
                <h1 className="hero-title-v-2">
                  <span className="hero-title-bg">
                    {" "}
                    Earn.{" "}
                    <span className="hero-title-accent-v-2">Connect.</span>
                    <span> Save money. </span>
                  </span>
                </h1>
                <p className="hero-text">
                  {" "}
                  Partner with us to empower eco-friendly rides, convenient
                  carpool, happy commuters and cost-effective ride shares.{" "}
                </p>
                <div className="download-buttons">
                  <a
                    href="https://apps.apple.com/app/triphoppa/id6736841581"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="download-apple-btn"
                  >
                    <img src="/assets/images/download-1.webp" alt="img" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.triphoppa.encounter"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="download-android-btn"
                  >
                    <img src="/assets/images/download-2.webp" alt="img" />
                  </a>
                </div>
              </div>
              <div className="hero-area-right">
                <img
                  className="download-blob"
                  src="/assets/images/purple-blob.svg"
                  alt="Yellow blob"
                />
                <img
                  className="download-phone-img"
                  src="/enc/images/mockups/1-1.png"
                  style={{ width: "25rem" }}
                  alt="Mobile phone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Herocta;
