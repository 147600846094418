import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <main className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div
        className="card shadow-lg p-4"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <div className="text-center mb-4">
          <a href="/">
            <img
              src="/enc/images/logo_3.png"
              alt="TripHoppa Logo"
              className="img-fluid mb-3"
              style={{ maxWidth: "70%" }}
            />
          </a>
        </div>
        <p className="display-3 text-primary text-center fw-bold">404!</p>
        <h1 className="h4 text-center mb-3 fw-bold">Page not found</h1>
        <p className="text-center">
          The page you are looking for was not found. If you think this is an
          issue, please contact support for help.
        </p>
        <div className="d-flex justify-content-center gap-3 mt-4">
          <button onClick={handleGoBack} className="btn btn-primary px-4">
            Go back
          </button>
          <a href="/contact-us">
            <button
              className="btn p-3 text-white fw-semibold d-flex align-items-center gap-2 contact-us"
              style={{ backgroundColor: "#96298d" }}
            >
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
