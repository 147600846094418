import { useState } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const AuthCarousel = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div
      id="mainCarousel"
      className="carousel slide h-100"
      data-bs-ride="carousel"
    >
      {/* Carousel Indicators */}
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <button
            key={`indicator-${index}`}
            type="button"
            data-bs-target="#mainCarousel"
            data-bs-slide-to={index}
            className={index === activeIndex ? "active" : ""}
            aria-current={index === activeIndex ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
            onClick={() => handleSlideChange(index)}
          ></button>
        ))}
      </div>

      {/* Carousel Items */}
      <div className="carousel-inner h-100">
        {slides.map((slide, index) => (
          <div
            key={`slide-${index}`}
            className={`carousel-item ${
              index === activeIndex ? "active" : ""
            } h-100`}
          >
            <img
              src={slide.imgSrc || "/enc/images/pict.png"}
              className="d-block w-100 h-100 img-fluid"
              alt={slide.title}
              style={{ objectFit: "cover" }}
            />
            <div className="carousel-caption">
              <h2 className="display-4">{slide.title}</h2>
              <p className="lead">{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuthCarousel;
