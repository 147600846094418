import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { api } from "../utils/fetcher";
import { useAuth } from "./AuthContext";

const DriverContext = createContext();

const useDriver = () => useContext(DriverContext);

const DriverProvider = ({ children }) => {
  const { user, accountType } = useAuth();
  const [driverInfo, setDriverInfo] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchDriverInfo = useCallback(async () => {
    if (accountType !== "DRIVER" || !user?.uid) return;

    setIsLoading(true);
    try {
      const { data } = await api.get("/driver/getDriverInfo", {
        params: { driverId: user.uid },
      });

      if (data.status && data.data) {
        setDriverInfo(data.data);
        setVerificationStatus(data.data.verificationStatus || "");
      } else {
        setDriverInfo(null);
        setVerificationStatus("");
      }
    } catch (error) {
      console.error("Error fetching driver info:", error);
      setDriverInfo(null);
      setVerificationStatus("");
    } finally {
      setIsLoading(false);
    }
  }, [accountType, user?.uid]);

  useEffect(() => {
    fetchDriverInfo();
  }, [fetchDriverInfo]);

  return (
    <DriverContext.Provider
      value={{
        driverInfo,
        verificationStatus,
        fetchDriverInfo,
        isLoading,
      }}
    >
      {children}
    </DriverContext.Provider>
  );
};

export { DriverProvider, useDriver };
