import React from "react";
import AuthInput from "../AuthInput";

const LoginInputs = ({
  credentials,
  handleChange,
  clearError,
  showPassword,
  handleShowPassword,
  errors,
}) => {
  return (
    <>
      <div className="mb-3">
        <AuthInput
          name="email"
          value={credentials.email}
          onChange={(e) => {
            handleChange(e);
            clearError("email");
          }}
          onFocus={() => clearError("email")}
          placeHolder="Email"
          type="text"
        />
        {errors.email && (
          <div
            className="text-danger small"
            style={{ width: "100%", maxWidth: "310px", fontSize: "10px" }}
          >
            {errors.email}
          </div>
        )}
      </div>

      <div className="mb-3">
        <AuthInput
          name="password"
          value={credentials.password}
          onChange={(e) => {
            handleChange(e);
            clearError("password");
          }}
          onFocus={() => clearError("password")}
          placeHolder="Password"
          type={showPassword ? "text" : "password"}
          handleShowPassword={handleShowPassword}
          showPassword={showPassword}
          isPassword={true}
        />
        {errors.password && (
          <div
            className="text-danger small"
            style={{
              width: "100%",
              maxWidth: "310px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              fontSize: "10px",
            }}
          >
            {errors.password}
          </div>
        )}
      </div>
    </>
  );
};

export default LoginInputs;
