import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthBtn from "../AuthBtn";
import LoginInputs from "./LoginInputs";
import LoginHeader from "./LoginHeader";
import LoginLinks from "./LoginLinks";
import { validateEmail, validatePassword } from "./LoginFormValidation";
import { initialFormData, initialErrors } from "./LoginFormStates";
import { useToast } from "../../../context/ToastContext";
import { api, AuthAPi } from "../../../utils/fetcher";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const showToast = useToast();

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    if (!validateEmail(data.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    }
    if (!validatePassword(data.password)) {
      valid = false;
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    setErrors(newErrors);
    return valid;
  };

  // Clear errors
  const clearError = (name) => {
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const checkAccountStatus = async (userId, token) => {
    try {
      const { data } = await AuthAPi.get(`/user/getAccountStatus`, {
        params: { userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.data.accountStatus;
    } catch (error) {
      console.error("Error checking account status:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.email.trim() || !data.password.trim()) {
      alert("Please fill in all fields");
      return;
    }

    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await api.post("/user/loginWithEmailAndPassword", data);
      console.log("Login Response:", res);
      if (res.data) {
        const { user, refreshToken } = res.data.data;

        if (!user || !refreshToken) {
          throw new Error("Missing user data or token");
        }
        sessionStorage.setItem("token", refreshToken);
        sessionStorage.setItem("user", JSON.stringify(user));
        // Check account status
        const userId = user?.user.uid;
        const accountStatus = await checkAccountStatus(userId, refreshToken);
        if (accountStatus === "Approved") {
          window.location.href = "/get-started";
        } else {
          window.location.href = "/verify";
        }
        showToast("success", "Logged in successfully!");
      }
    } catch (error) {
      showToast("error", "Login failed. Please check your email and password.");
    }

    setLoading(false);
  };

  return (
    <>
      <div className="auth-form min-h-screen flex flex-col items-center justify-center relative">
        {/* Logo Section */}
        <div
          style={{
            textAlign: "center",
            marginBottom: "100px",
          }}
        >
          <Link to="/get-started">
            <img
              src="/enc/images/logo_3.png"
              alt="logo"
              style={{ width: "200px" }}
            />
          </Link>
        </div>

        {/* Login Form Section */}
        <div className="w-full max-w-md">
          <div className="card border-0">
            <div className="card-body">
              <LoginHeader />

              <form onSubmit={handleSubmit}>
                <LoginInputs
                  credentials={data}
                  handleChange={handleChange}
                  clearError={clearError}
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                  errors={errors}
                  className="form-control-sm"
                />

                <AuthBtn
                  text="Log In"
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                />

                <LoginLinks />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
