import React from "react";

const LoginHeader = () => {
  return (
    <div className="text-center mb-4">
      <h2 className="mb-2 fs-3">Log in to your Account</h2>
      <p className="text-muted">Welcome back</p>
    </div>
  );
};

export default LoginHeader;
