import React from "react";

const AuthBtn = ({ text, onClick, disabled, loading }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn text-white w-100 ${disabled ? "opacity-50" : ""}`}
      style={{ backgroundColor: "#96298d" }}
    >
      {loading ? (
        <>
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </>
      ) : (
        text
      )}
    </button>
  );
};

export default AuthBtn;
