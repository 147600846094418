import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import MainLayout from "../../components/DriversPortal/Layout/MainLayout";
import { HeaderProvider } from "../../context/LayoutContext";
import { useAuth } from "../../context/AuthContext";
import Preloader from "../../components/More/Loader/Preloader";
import NotFound from "../404";
import "./driverportal.css";

// Lazy load dashboard pages
const Overview = React.lazy(() =>
  import("../../components/DriversPortal/Scene/Overview/Overview")
);
// const ProfileEdit = React.lazy(() =>
//   import("../../components/DriversPortal/Scene/Settings/ProfileEdit")
// );
const CarpoolTrips = React.lazy(() =>
  import("../../components/DriversPortal/Scene/Trips/Carpool")
);
const ParcelTrips = React.lazy(() =>
  import("../../components/DriversPortal/Scene/Trips/Parcel")
);

const DriverPortal = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { accountStatus } = useAuth();

  useEffect(() => {
    // Redirect if user is not a driver
    // if (accountType && accountType !== "Driver") {
    //   navigate("/");
    //   return;
    // }

    if (accountStatus !== "Approved") {
      navigate("/verify");
      return;
    }
    setIsLoading(false);
  }, [accountStatus, navigate]);
  if (isLoading) {
    return <Preloader />;
  }

  return (
    <HeaderProvider>
      <React.Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<Overview />} />
            <Route path="trips">
              <Route path="carpool" element={<CarpoolTrips />} />
              <Route path="parcel" element={<ParcelTrips />} />
            </Route>
            {/* <Route path="settings" element={<ProfileEdit />} /> */}
            {/* <Route path="trips" element={<Trips />} />
            <Route path="verification" element={<Verification />} />
            <Route path="requests" element={<Requests />} />
            <Route path="reports" element={<Reports />} />
            <Route path="settings" element={<Settings />} /> */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </HeaderProvider>
  );
};

export default DriverPortal;
