export const initialFormData = {
  email: "",
  password: "",
  fname: "",
  lname: "",
  phone: "",
  country: "",
  confPassword: "",
};

export const initialErrors = {
  email: "",
  password: "",
  confPassword: "",
  phoneNumber: "",
};
