import React from "react";
import { Link } from "react-router-dom";

const SignupLinks = () => {
  return (
    <>
      <div className="text-center mt-3">
        <p className="text-muted">
          Have an account?{" "}
          <Link to="/auth/login" className="text-decoration-none fw-semibold">
            Login
          </Link>
        </p>
      </div>
    </>
  );
};

export default SignupLinks;
